import { handleApiCallsVisualizationEngine } from '../actions/actionHelper';
import { getKpiData } from "./kpiDataService";
import { getCategoryData } from './categoryDataService';

// Base fetch function (renamed to _fetchDashboardData to avoid naming conflict)
const _fetchDashboardData = async (endpoint) => {
  const responseData = await handleApiCallsVisualizationEngine(
    "get",
    endpoint,
    ""
  );

  if (responseData[2] !== "") {
    throw new Error(responseData[2]);
  }

  const dashboardData = responseData[1];
  
  // Fetch KPI data
  const kpiData = await fetchAndTransformKpiData(dashboardData.layoutKpiInfo);
  
  
  // Fetch category data with the extracted category IDs
  const categoryData = await fetchAndTransformCategoryData(dashboardData.layoutCategoryInfo);

  return {
    layoutInfo: dashboardData.layoutInfo,
    kpiData,
    categoryData
  };
};

// Specialized fetch functions
export const fetchPreviewDashboardData = async (id) => {
  return _fetchDashboardData(`dashboard/${id}/preview`);
};

export const fetchUserDashboardData = async () => {
  return _fetchDashboardData('user/dashboard');
};

export const fetchDashboardData = async (dashboardName) => {
  const encodedName = encodeURIComponent(dashboardName);
  return _fetchDashboardData(`dashboards/${encodedName}`);
};

// Helper functions remain private
const fetchAndTransformKpiData = async (kpiIds) => {
  if (!kpiIds?.length) return {};

  const kpiPromises = kpiIds.map(kpi => getKpiData(kpi));
  const kpiResults = await Promise.all(kpiPromises);
  
  return kpiResults.reduce((acc, result, index) => {
    acc[kpiIds[index]] = result[1];
    return acc;
  }, {});
};

const fetchAndTransformCategoryData = async (categoryIds) => {
  if (!categoryIds?.length) return {};

  const categoryPromises = categoryIds.map(async (categoryId) => {
    return await getCategoryData(categoryId);
  });
  
  const categoryResults = await Promise.all(categoryPromises);

  return categoryResults.reduce((acc, result, index) => {
    if (result?.id) {
      acc[result.id] = result;
    } else {
      console.warn(`Category data for ${categoryIds[index]} is missing or invalid`);
    }
    return acc;
  }, {});
};
