import { useState, useEffect } from 'react';
import { handleApiCallsVisualizationEngine } from '../actions/actionHelper';
import { fetchPreviewDashboardData } from '../utils/dashboardDataService';

export const useFetchAdminDashboardPreviewLayout = (dashboardId) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [layoutInfo, setLayoutInfo] = useState(null);
  const [kpiData, setKpiData] = useState({});
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    fetchDashboardPreviewData(dashboardId);
  }, [dashboardId]);

  const fetchDashboardPreviewData = async (id) => {
    setLoading(true);
    setError(null);

    try {
      const { layoutInfo: layout, kpiData: kpis, categoryData: categories } = await fetchPreviewDashboardData(id);
      
      setLayoutInfo(layout);
      setKpiData(kpis);
      setCategoryData(categories);

    } catch (error) {
      setError(error.message || 'An error occurred while fetching dashboard preview data');
    } finally {
      setLoading(false);
    }
  };

  const updateLayout = async (layouts) => {
    try {
      await handleApiCallsVisualizationEngine(
        "post",
        `dashboard/${dashboardId}/layout/update`,
        '',
        { layoutInfo: layouts }
      );
      setLayoutInfo(layouts);
    } catch (error) {
      setError(error.message || 'An error occurred while updating layout');
    }
  };

  return { 
    loading, 
    error, 
    layoutInfo, 
    kpiData, 
    categoryData, 
    updateLayout 
  };
};
