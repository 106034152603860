import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import DragAndDropLayout from '../../../components/Dashboard/DragAndDropLayout';
import { useFetchDashboardLayoutData } from '../../../hooks/useFetchDashboardLayoutData';
import { useSearchParams } from 'react-router-dom';

const Analytics = () => {
  const [searchParams] = useSearchParams();
  const dashboardName = searchParams.get("dashboard");
  const { loading, error, layoutInfo, kpiData, categoryData } = useFetchDashboardLayoutData(dashboardName);

  useEffect(() => {
    console.log("analytics", layoutInfo, kpiData, categoryData);
  }, [layoutInfo, kpiData, categoryData]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Analytics Dashboard
      </Typography>
      <DragAndDropLayout
        userDefineLayout={layoutInfo}
        kpisData={kpiData}
        categoryData={categoryData}
        updateLayoutOnChange={false}
      />
    </Box>
  );
};

export default Analytics;
