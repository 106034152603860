import React, { useEffect, useState } from 'react'
import { alpha, styled, useTheme } from '@mui/material/styles';
import { tokens } from "../../../theme";
import { Box, Menu, MenuItem, Typography, Button, FormControlLabel, Switch, Skeleton, Chip, IconButton, Tooltip } from "@mui/material";
import DragAndDropLayout from '../../../components/Dashboard/DragAndDropLayout';
import { useDispatch, useSelector } from 'react-redux';
import { GetDashboardAllKpisList, UpdateDashboardAllKpisListItem, ResetDashboardLayoutData } from '../../../actions/dashboardActions';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { green } from '@mui/material/colors';
import AccessControl from "../../../AccessControl";
import SmallCustomSwitch from '../../../components/Switch/smallCustomSwitch';
import CustomWidthTooltip from '../../../components/General/tooltip';
import { Assessment } from '@mui/icons-material';
import { reportDownload } from '../../../actions/actionHelper/reportDowload';
import useFetchUserDashboardLayout from '../../../hooks/useFetchUserDashboardLayout';


const CustomColorSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));


function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch()

  const { 
    loading: layoutLoading, 
    error: layoutError, 
    layoutInfo, 
    kpiData, 
    categoryData, 
    updateLayout 
  } = useFetchUserDashboardLayout();

  useEffect(() => {
    // console.log("layoutInfo", layoutInfo);
    // console.log("kpiData", kpiData);
    // console.log("categoryData", categoryData);
  }, [layoutInfo, kpiData, categoryData]);

  const myDashboardAllKpisList = useSelector(state => state.myDashboardAllKpisList)
  const { error: allKpisListError, loading: allKpisListLoading, dashboardAllKpisNameList } = myDashboardAllKpisList

  const [downloadReportEnable, setDownloadReportEnable] = useState(true)

  useEffect(() => {
    dispatch(GetDashboardAllKpisList());
  }, [dispatch])

  const [dashboardItemsOption, setdashboardItemsOption] = React.useState(null);
  const DashboardMenuOpen = Boolean(dashboardItemsOption);

  const handleLayoutChange = (layout, layouts) => {
    updateLayout(layouts);
  };

  const handleAddDashboardClick = (event) => {
    setdashboardItemsOption(event.currentTarget);
  };

  const handleAddDashboardItemsChange = (changedItem) => {
    console.log(changedItem)
    dispatch(UpdateDashboardAllKpisListItem([!changedItem[1][1], Number(changedItem[0])]));
  };

  const handleAddDashboardClose = () => {
    setdashboardItemsOption(null);
  };

  const handleLayOutResetClick = () => {
    dispatch(ResetDashboardLayoutData());
    handleAddDashboardClose()
  }

  return (

    <div
      style={{
        padding: "0em 2em 6em 2em",
        overflowX: "auto",
      }}
    >
      {/* Section Header */}
      <Box
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", }}
      >
        {/* Section Header - Title */}
        <Box sx={{ display: 'flex', lineheight: 0, flexDirection: 'column', margin: "1em 0em 1em 0em" }}>
          <Typography component="div" variant="h3">
            My Dashboard
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Welcome to Dashboard
          </Typography>
        </Box>
        {/* Section Header - Buttons */}
        <Box>
          {
            allKpisListLoading ?
              <Skeleton variant="rounded" animation="wave" width={150} height={30} />
              : allKpisListError ?
                <Box
                  style={{
                    height: '20vh',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Typography>Unable to Collect All Avaliable KPIS List</Typography>
                </Box>
                : (
                  <>
                    {/* <Tooltip title="Dowload report related to KPI">
                        <IconButton onClick={()=>{
                          reportDownload()
                        }}> 
                          <Assessment/> 
                        </IconButton>
                      </Tooltip> */}

                    {/* Download Report Button */}
                   {/* <Tooltip title="Dowload report summary">
                      <Button
                        component="label"
                        variant="contained"
                        onClick={() => { reportDownload(setDownloadReportEnable) }}
                        startIcon={<Assessment style={{ color: `${colors.IconColor}` }} />}
                        style={{
                          backgroundColor: `${colors.dashboardManageViewButton}`,
                          marginRight: "10px",
                          opacity: !downloadReportEnable ? "20%" : "100%"
                        }}
                        disabled={!downloadReportEnable}
                      >
                        <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.TextColor}`}>
                          Download Report
                        </Typography>
                      </Button>
                    </Tooltip> */}

                    {/* Manage View Button */}
                    <Button
                      component="label"
                      variant="contained"
                      aria-controls={DashboardMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={DashboardMenuOpen ? 'true' : undefined}
                      onClick={handleAddDashboardClick}
                      startIcon={<AddCardOutlinedIcon style={{ color: `${colors.IconColor}` }} />}
                      style={{ backgroundColor: `${colors.dashboardManageViewButton}` }}
                    >
                      <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.TextColor}`}>
                        Manage View
                      </Typography>

                    </Button>

                    {/* Manage View Menu */}
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      // disableScrollLock={true}
                      anchorEl={dashboardItemsOption}
                      open={DashboardMenuOpen}
                      onClose={handleAddDashboardClose}
                      PaperProps={{
                        style: {
                          maxHeight: 250,
                          width: '140px', //23
                        },
                      }}
                    >
                      <AccessControl allowedPermissions={['setDefaultKpi']} >
                        <MenuItem key={'reset_layout'}>
                          {/* <Button 
                      variant="contained" 
                      onClick={handleLayOutResetClick}
                      style={{backgroundColor:`${colors.dashboardResetAllButton}`}} 
                      endIcon={<RestartAltOutlinedIcon style={{color:`${colors.IconColor}`}} />}
                    >
                        <Typography fontWeight={'medium'} color={`${colors.TextColor}`}>
                            Reset All
                        </Typography>
                    </Button> */}
                          <Chip avatar={<RestartAltOutlinedIcon />} onClick={handleLayOutResetClick} label="Reset All" />
                        </MenuItem>
                      </AccessControl>
                      {Object.entries(dashboardAllKpisNameList).map((option) => (
                        <MenuItem key={option[0]}>
                          {/* <FormControlLabel 
                      sx={{ ml:0 , mr:0}}  //important for mui5, don't remove it
                      control={
                        <AccessControl allowedPermissions={["manageViewDashboard"]}>
                          <CustomSwitch
                            switchCircleColor={colors.Switch}
                            switchSliderColor={colors.SwitchSlider}
                            checked={option[1][1]} 
                            onChange={() => handleAddDashboardItemsChange(option)}
                          />
                        </AccessControl>
                        } 
                      label={option[1][0]}
                      labelPlacement="start"
                    /> */}
                          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CustomWidthTooltip title={option[1][0]}>
                              <Typography maxWidth={'100px'} noWrap={true}>{option[1][0]}</Typography>
                            </CustomWidthTooltip>
                            <AccessControl allowedPermissions={["manageViewDashboard"]}>
                              <SmallCustomSwitch
                                switchcirclecolor={colors.Switch}
                                switchslidercolor={colors.SwitchSlider}
                                checked={option[1][1]}
                                onChange={() => handleAddDashboardItemsChange(option)}
                              />
                            </AccessControl>
                          </Box>
                        </MenuItem>
                      ))}

                    </Menu>
                  </>
                )
          }
        </Box>
      </Box>

      {/* KPI Cards */}
      {
        layoutLoading ? <Skeleton variant="rounded" animation="wave" sx={{ borderRadius: '10px' }} width={'100%'} height={'80vh'} />
          : layoutError ?
            <Box
              style={{
                height: '60vh',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography>Unable to Collect Layout Data</Typography>
            </Box>
            : (
              // <Box sx={{background:'#f6f7f8', borderRadius:'10px'}}> background:colors.primary[800],
              <Box sx={{ borderRadius: '10px' }}>
                <DragAndDropLayout 
                  userDefineLayout={layoutInfo}
                  onLayoutChange={handleLayoutChange}
                  categoryData={categoryData}
                  kpisData={kpiData}
                />
              </Box>
            )
      }
    </div>
  )
}

export default Dashboard;
