import * as React from 'react';
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Menu, IconButton, Toolbar, Avatar, AppBar, Typography, Dialog, DialogActions, DialogTitle, Slide, ListItemIcon, useTheme, MenuItem, Container, Button, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from "@mui/icons-material/Storage"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
// import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AuthContext from "../../Auth/authProvider"
import AccessControl from '../../AccessControl';
import { getAllNotification, getUnreadNotification } from '../../actions/notificationActions';
import { useDispatch } from 'react-redux';
import state from './../../AccessControl';
import CustomBreadcrumb from './Breadcrumbs';
import { ExpandMore } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NatureOutlinedIcon from '@mui/icons-material/NatureOutlined';

const pages = ['Home', 'Energy Insights'];
const pagesIcon = {
  'Home': <HomeOutlinedIcon />,
  'Device Management': <DevicesOtherOutlinedIcon />,
  'Energy Insights': <AnalyticsOutlinedIcon />,
}
const pagesRoutes = {
  'Home': '/',
  'Device Management': '/device-manage',
  'Energy Insights': `/analytics?dashboard=${process.env.REACT_APP_ENERGY_INSIGHTS_ID}`,//to be changed
}

const LogOutDialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dashboardItems = [
  { 
    name: 'Energy Use',
    link: `/analytics?dashboard=${encodeURIComponent("Energy Insights")}`
  },
  { 
    name: 'Home Health',
    link: '/'
  },
  { 
    name: 'Energy Cost',
    link: `/analytics?dashboard=${encodeURIComponent("Energy Cost")}`
  },
  { 
    name: 'Carbon Impact',
    link: `/analytics?dashboard=${encodeURIComponent("Carbon Impact")}`
  },
];

const mobileMenuItems = [
  { 
    name: 'Home',
    link: '/'
  },
  ...dashboardItems,
  {
    name: "FAQ's",
    link: '/documentation'
  },
  {
    name: 'Contact us',
    link: '/report-feedback'
  },
  {
    name: 'Manage Account',
    link: '/user-account'
  },
  {
    name: 'Device Management',
    link: '/device-manage'
  },
  {
    name: 'Logout',
    link: null
  }
];

const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    backgroundColor: "#F99D1C", // Tangerine Pantone 144
    "& .MuiMenuItem-root": {
      backgroundColor: "#F99D1C",
      "&:hover": {
        backgroundColor: "#ffae3d", // Slightly lighter shade for hover
      },
    },
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "#F99D1C",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const Topbar = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, userInfo } = userLogin;
  // console.log("userInfo",userInfo)
  const userReducer2 = useSelector((state) => state.userReducer2)
  const dispatch = useDispatch();

  const notificationReducer = useSelector((state) => state.notificationReducer)
  const { allNotification, unreadNotification } = notificationReducer

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  //const userSession=sessionStorage.getItem("user")
  let { logoutUser } = useContext(AuthContext)

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [AnchorElHelp, setAnchorElHelp] = React.useState(null);
  const [AnchorElAdmin, seAnchorElAdmin] = React.useState(null);
  const [AnchorElAdminUserRole, seAnchorElAdminUserRole] = React.useState(null);
  const [AnchorElAdminVisualization, seAnchorElAdminVisualization] = React.useState(null);
  const [AnchorElTheme, seAnchorElTheme] = React.useState(null);
  const [AnchorElContentAdmin, seAnchorElContentAdmin] = React.useState(null);
  const [logoutDialogOpen, setlogoutDialogOpen] = React.useState(false);
  const [anchorElHelpSupport, setAnchorElHelpSupport] = React.useState(null);
  const [anchorElDashboards, setAnchorElDashboards] = React.useState(null);

  React.useEffect(() => {
    if (userLogin.isAuthenticated && "userInfo" in userLogin && "permission" in userLogin.userInfo) {
      setTimeout(() => {
        dispatch(getAllNotification())
        dispatch(getUnreadNotification())
      }, 2000)

    }
  }, [userLogin])

  const UserOpen = Boolean(anchorElUser);
  const HelpOpen = Boolean(AnchorElHelp);
  const AdminOpen = Boolean(AnchorElAdmin);
  const AdminUserRoleOpen = Boolean(AnchorElAdminUserRole);
  const AdminVisualizationOpen = Boolean(AnchorElAdminVisualization);
  const ContentAdminOpen = Boolean(AnchorElContentAdmin);
  const ThemeOpen = Boolean(AnchorElTheme);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenHelpMenu = (event) => {
    setAnchorElHelp(event.currentTarget);
  };
  const handleOpenAdminMenu = (event) => {
    seAnchorElAdmin(event.currentTarget);
  };
  const handleOpenAdminUserRoleMenu = (event) => {
    seAnchorElAdminUserRole(event.currentTarget);
  };
  const handleOpenAdminVisualizationMenu = (event) => {
    seAnchorElAdminVisualization(event.currentTarget);
  };
  const handleOpenContentAdminMenu = (event) => {
    seAnchorElContentAdmin(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseAdminMenu = () => {
    seAnchorElAdmin(null);
  };
  const handleCloseAdminUserRoleMenu = () => {
    seAnchorElAdminUserRole(null);
  };
  const handleCloseAdminVisualizationMenu = () => {
    seAnchorElAdminVisualization(null);
  };
  const handleCloseContentAdminMenu = () => {
    seAnchorElContentAdmin(null);
  };

  const handleLogoutDialogOpen = () => {
    setlogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setlogoutDialogOpen(false);
  };

  const handleLogOut = () => {
    logoutUser()
    setlogoutDialogOpen(false);
  };

  const handleOpenHelpSupportMenu = (event) => {
    setAnchorElHelpSupport(event.currentTarget);
  };

  const handleCloseHelpSupportMenu = () => {
    setAnchorElHelpSupport(null);
  };

  const handleOpenDashboardsMenu = (event) => {
    setAnchorElDashboards(event.currentTarget);
  };

  const handleCloseDashboardsMenu = () => {
    setAnchorElDashboards(null);
  };

  const handleMobileMenuItemClick = (item) => {
    handleCloseNavMenu();
    if (item.name === 'Logout') {
      handleLogoutDialogOpen();
    }
  };

  return (

    <>
      {isAuthenticated === false ? <></>
        :
        <>
          <AppBar position="sticky" style={{ background: colors.navbar }} >
            <Container maxWidth="xxl">
              <Toolbar 
                disableGutters 
                sx={{ 
                  minHeight: '80px !important', // Increased height
                  height: '80px' // Explicit height
                }}
              >

                <Link to="/">
                  <Box
                    component="img"
                    sx={{
                      height: 50,
                      width: 'auto',
                      mr: 2
                    }}
                    alt="Energize Bridgewater Logo"
                    src="../../assets/logo-energize.png"
                  />
                </Link>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', gap: 1 }}>
                  <AccessControl allowedPermissions={["userPrivilege"]} >
                    <>
                      <Button
                        onClick={handleOpenDashboardsMenu}
                        sx={{ pr: 2, pl: 2, fontWeight: 'bold', textTransform: 'none' }}
                      >
                        Dashboard
                      </Button>
                      <Menu
                        anchorEl={anchorElDashboards}
                        open={Boolean(anchorElDashboards)}
                        onClose={handleCloseDashboardsMenu}
                        onClick={handleCloseDashboardsMenu}
                        PaperProps={menuPaperProps}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      >
                        {dashboardItems.map((item) => (
                          <MenuItem
                            key={item.name}
                            component={Link}
                            to={item.link}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Menu>

                      <Button
                        key={"HelpSupport"}
                        onClick={handleOpenHelpSupportMenu}
                        sx={{ pr: 2, pl: 2, fontWeight: 'bold', textTransform: 'none' }}
                      >
                        Help & Support
                      </Button>
                      <Menu
                        anchorEl={anchorElHelpSupport}
                        id="help-support-menu"
                        open={Boolean(anchorElHelpSupport)}
                        onClose={handleCloseHelpSupportMenu}
                        onClick={handleCloseHelpSupportMenu}
                        PaperProps={menuPaperProps}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      >
                        <MenuItem component={Link} to='/documentation'>
                          FAQ's
                        </MenuItem>
                        <MenuItem component={Link} to='/report-feedback'>
                          Contact us
                        </MenuItem>
                      </Menu>
                    </>
                  </AccessControl>

                  <AccessControl allowedPermissions={["adminPrivilege"]} >
                    <Button
                      key={"Admin"}
                      onClick={handleOpenAdminMenu}
                      sx={{ pr: 2, pl: 2, fontWeight: 'bold', textTransform: 'none', mr: 2 }}
                    >
                      {"Admin"}
                    </Button>

                    <Menu
                      anchorEl={AnchorElAdmin}
                      id="admin"
                      open={AdminOpen}
                      onClose={handleCloseAdminMenu}
                      PaperProps={menuPaperProps}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={(event) => { handleOpenAdminUserRoleMenu(event) }}>
                        User & Role
                      </MenuItem>
                      <MenuItem onClick={(event) => { handleOpenAdminVisualizationMenu(event) }}>
                        Visualization
                      </MenuItem>
                      <MenuItem onClick={(event) => { handleOpenContentAdminMenu(event) }}>
                        Manage Content
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElAdminUserRole}
                      id="adminUserRole"
                      open={AdminUserRoleOpen}
                      onClose={handleCloseAdminUserRoleMenu}
                      onClick={() => { handleCloseAdminUserRoleMenu(); handleCloseAdminMenu() }}
                      PaperProps={menuPaperProps}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <MenuItem
                        component={Link}
                        to='/admin-manage'
                      >
                        Admin Management
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/users-manage'
                      >
                        Users Management
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/users-and-devices'
                      >
                        Users & Devices
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/role-permission'
                      >
                        Roles
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/audit-log'
                      >
                        Audit Log
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElAdminVisualization}
                      id="adminVisualization"
                      open={AdminVisualizationOpen}
                      onClose={handleCloseAdminVisualizationMenu}
                      onClick={() => { handleCloseAdminVisualizationMenu(); handleCloseAdminMenu() }}
                      PaperProps={menuPaperProps}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <MenuItem component={Link} to='/filter-manage'>
                        KPI Filters
                      </MenuItem>
                      <MenuItem component={Link} to='/kpi-manage'>
                        KPIs
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/manage-category'
                      >
                        Categories
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/manage-dashboard'
                      >
                        Dashboards
                      </MenuItem>
                      <MenuItem component={Link} to='/db-manage'>
                        Manage Database
                      </MenuItem>
                    </Menu>

                    <Menu
                      anchorEl={AnchorElContentAdmin}
                      id="adminContent"
                      open={ContentAdminOpen}
                      onClose={handleCloseContentAdminMenu}
                      onClick={() => { handleCloseContentAdminMenu(); handleCloseAdminMenu() }}
                      PaperProps={menuPaperProps}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <MenuItem
                        component={Link}
                        to="/manage-documentation"
                      >
                        FAQ's
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/manage-daily-tips'
                      >
                        Manage Daily Tips
                      </MenuItem>
                    </Menu>
                  </AccessControl>
                </Box>

                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>

                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === "dark" ? (
                    <LightModeOutlinedIcon />
                  ) : (
                    <DarkModeOutlinedIcon />
                  )}
                </IconButton>
                <IconButton to="/notifications" component={Link} sx={{ mr: 3 }}>
                  <Badge badgeContent={unreadNotification ? unreadNotification : null} color="primary">
                    <NotificationsNoneOutlinedIcon fontSize='medium' />
                  </Badge>
                </IconButton>

                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    key={'Account Settings'}
                    onClick={handleOpenUserMenu}
                    sx={{ 
                      pr: 2, 
                      pl: 2,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                    aria-controls={UserOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={UserOpen ? "true" : undefined}
                  >
                    Account Settings
                  </Button>
                  <Menu
                    anchorEl={anchorElUser}
                    id="account-menu"
                    open={UserOpen}
                    onClose={handleCloseUserMenu}
                    onClick={handleCloseUserMenu}
                    PaperProps={menuPaperProps}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <ListItem
                      sx={{ pb: 0, pt: 0 }}
                    >
                      <Avatar />
                      <ListItemText
                        primary={`${userInfo.user_email}`}
                        secondary={userInfo.role}
                      />
                    </ListItem>

                    <MenuItem
                      component={Link}
                      to='/user-account'
                    >
                      Manage Account
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to='/device-manage'
                    >
                      Device Management
                    </MenuItem>

                    <MenuItem
                      onClick={handleLogoutDialogOpen}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                  <Dialog
                    open={logoutDialogOpen}
                    TransitionComponent={LogOutDialogTransition}
                    keepMounted
                    onClose={handleLogoutDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}
                      >
                        <LogoutOutlinedIcon fontSize="small" />
                        <Typography>
                          Do you want to Logout?
                        </Typography>
                      </Box>
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleLogoutDialogClose}>Cancel</Button>
                      <Button onClick={handleLogOut}>OK</Button>
                    </DialogActions>
                  </Dialog>
                </Box>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                  PaperProps={menuPaperProps}
                >
                  {/* Updated mobile navbar */}
                  {mobileMenuItems.map((item) => (
                    <MenuItem key={item.name} onClick={() => handleMobileMenuItemClick(item)}>
                      <Button
                        startIcon={item.icon}
                        component={item.link ? Link : 'button'}
                        to={item.link}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              </Toolbar>
            </Container>
          </AppBar>
          <CustomBreadcrumb />
        </>
      }
    </>

  );
};

export default Topbar;