import { handleApiCallsVisualizationEngine } from "./actionHelper";
import {
    DASHBOARD_PREVIEW_LAYOUT_REQUEST,
    DASHBOARD_PREVIEW_LAYOUT_SUCCESS,
    DASHBOARD_PREVIEW_LAYOUT_FAIL,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS,
    DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL,

    DASHBOARD_PREVIEW_KPI_DATA_REQUEST,
    DASHBOARD_PREVIEW_KPI_DATA_SUCCESS,
    DASHBOARD_PREVIEW_KPI_DATA_FAIL,
    DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE,
    DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST,
    DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS,
    DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL,
} from "../constants/adminDashboardPreviewConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { getKpiData } from "../utils/kpiDataService";


export const GetAdminDashboardPreviewLayoutData = (dashboard_id) => async (dispatch) => {
    let responseData = [];

    try {
        dispatch({ type: DASHBOARD_PREVIEW_LAYOUT_REQUEST});

        responseData = await handleApiCallsVisualizationEngine(
        "get",
        `dashboard/${dashboard_id}/preview`,
        ""
        );

        if (responseData[2] === "") {
        dispatch({
            type: DASHBOARD_PREVIEW_LAYOUT_SUCCESS,
            payload: responseData[1].layoutInfo,
            kpisList:  responseData[1].layoutKpiInfo,
            categoryList: responseData[1].layoutCategoryInfo
        });
        } else {
        dispatch({
        type: DASHBOARD_PREVIEW_LAYOUT_FAIL,
        payload: responseData[2],
        });

        dispatch(showGlobalSnackbar(responseData[2], "error"));
        }

    } catch (error) {
        let temp_error = ''
        if(typeof error === 'object'){
          temp_error =  error.response.data.error !== undefined ? error.response.data.error : 'Error !' 
        }
        dispatch({
        type: DASHBOARD_PREVIEW_LAYOUT_FAIL,
        payload: temp_error  || 'Error!',
        });

        dispatch(showGlobalSnackbar(temp_error || 'Error!', "error"));
    }
};



export const UpdatePreviewDashboardLayoutData = (dashboard_id, layoutData) => async (dispatch) => {
    let responseData = [];
  
      try {
        dispatch({ type: DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST });
  
        responseData = await handleApiCallsVisualizationEngine(
          "post",
          `dashboard/${dashboard_id}/layout/update`,
          '',
          { layoutInfo: layoutData }
        );
  
        if (responseData[2] === "") {
          dispatch({
            type: DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS,
            payload: layoutData,
            // kpisList:  responseData[1].layoutKpiInfo,
          });
        } else {
          dispatch({
            type: DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL,
            payload: responseData[2],
          });
          dispatch(showGlobalSnackbar(responseData[2], "error"));
        }
  
      } catch (error) {
        let temp_error = ''
        if(typeof error === 'object'){
          temp_error =  error.response.data.error !== undefined ? error.response.data.error : 'Error !' 
        }
        dispatch({
          type: DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL,
          payload: temp_error,
        });
        dispatch(
          showGlobalSnackbar(temp_error, "error")
        );
      }
    };

export const GetAdminDashboardPreviewEachKpiData = (kpiId) => async (dispatch) => {
    let responseData = [];
  
    try {
      dispatch({ type: DASHBOARD_PREVIEW_KPI_DATA_REQUEST });
  
      responseData = await getKpiData(kpiId);
  
      if (responseData[2] === "") {
        dispatch({
          type: DASHBOARD_PREVIEW_KPI_DATA_SUCCESS,
          payload: responseData[1],
        });
      } else {
        dispatch({
          type: DASHBOARD_PREVIEW_KPI_DATA_FAIL,
          kpiData: {},
          kpiId: kpiId,
          payload: responseData[2],
        });
        dispatch(showGlobalSnackbar(responseData[2], "error"));
      }
  
    } catch (error) {
      let temp_error = ''
      if(typeof error === 'object'){
        temp_error =  error.response.data.error !== undefined ? error.response.data.error : 'Error !' 
      }
      dispatch({
        type: DASHBOARD_PREVIEW_KPI_DATA_FAIL,
        kpiData: {},
        kpiId: kpiId,
        payload: temp_error,
      });
      dispatch(showGlobalSnackbar(temp_error, "error"));
    }
  };

export const GetAdminDashboardPreviewCategoryData = (categoryId) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST });

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `categories/${categoryId}`,
      ""
    );

    if (responseData[2] === "") {

      if (responseData[1].kpiIds) {
        console.log(`getting kpi data for category ${categoryId} with kpiIds ${responseData[1].kpiIds}`);
        const kpiDataPromises = responseData[1].kpiIds.map(async (kpiId) => {
            const kpiRes = await getKpiData(kpiId);
            return kpiRes[1];
        });
        const kpiData = await Promise.all(kpiDataPromises);
        console.log(`kpi data for category ${categoryId} with kpiIds ${responseData[1].kpiIds} is ${kpiData}`);
        responseData[1].kpiData = kpiData;
      }

      dispatch({
        type: DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL,
        categoryData: {},
        categoryId,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }
  } catch (error) {
    let temp_error = ''
    if(typeof error === 'object'){
      temp_error =  error.response.data.error !== undefined ? error.response.data.error : 'Error !' 
    }
    dispatch({
      type: DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL,
      categoryData: {},
      categoryId,
      payload: temp_error,
    });
    dispatch(showGlobalSnackbar(temp_error, "error"));
  }
};

export const UpdateDashboardPreviewOneKpiGraphType = (changedGraphTypeDetails) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE,
    payload: changedGraphTypeDetails,
  });
};

export const UpdateDashboardPreviewOneKpiGraphDataByFilter = (filterData, kpiId) => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: DASHBOARD_PREVIEW_KPI_DATA_REQUEST });

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `kpi/${kpiId}/data?filters=${JSON.stringify(filterData)}`,
      ""
    );

    if (responseData[2] === "") {
      dispatch({
        type: DASHBOARD_PREVIEW_KPI_DATA_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: DASHBOARD_PREVIEW_KPI_DATA_FAIL,
        kpiData: {},
        kpiId: kpiId,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    let temp_error = ''
    if(typeof error === 'object'){
      temp_error =  error.response.data.error !== undefined ? error.response.data.error : 'Error !' 
    }
    dispatch({
      type: DASHBOARD_PREVIEW_KPI_DATA_FAIL,
      kpiData: {},
      kpiId: kpiId,
      payload: temp_error,
    });
    dispatch(showGlobalSnackbar(temp_error, "error"));
  }
};