import React from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Typography, Skeleton } from "@mui/material";
import DragAndDropLayout from '../../../../../components/Dashboard/DragAndDropLayout';
import { useFetchAdminDashboardPreviewLayout } from '../../../../../hooks/useFetchAdminDashboardPreviewLayout';

export default function DashboardPreview() {
  const search = useLocation().search
  const dashboardQueryEditParams = new URLSearchParams(search)
  const dashboard_id = dashboardQueryEditParams.get("dashboard")

  const { 
    loading, 
    error, 
    layoutInfo, 
    kpiData, 
    categoryData, 
    updateLayout 
  } = useFetchAdminDashboardPreviewLayout(dashboard_id);

  const handleLayoutChange = (layout, layouts) => {
    updateLayout(layouts);
  };

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={400} />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box style={{ padding: "0em 2em 2em 2em", overflowX: "auto" }}>
      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"space-between"}}>
        <Box sx={{ display: 'flex', lineHeight: 0, flexDirection: 'column', margin: "1em 0em 1em 0em"}}>
          <Typography component="div" variant="h3">
            Dashboard Preview
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderRadius: '10px' }}>
        <DragAndDropLayout 
          userDefineLayout={layoutInfo}
          onLayoutChange={handleLayoutChange}
          isAdmin={true}
          dashboard_id={dashboard_id}
          categoryData={categoryData}
          kpisData={kpiData}
        />
      </Box>
    </Box>
  )
}
