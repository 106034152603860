import React from 'react';
import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import { Email, Instagram } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const backgroundColor = colors?.footer || "#76B043";

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor,
        color: '#FFFFFF',
        py: 4,
        borderTop: '1px solid transparent',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 20px',
      }}
    >
      <Box 
        sx={{
          width: '100%',
          height: '100%',
          padding: '50px 60px 50px 40px',
        }}
      >
        <Grid container spacing={4} sx={{ height: '100%', minHeight: '160px' }}>
          <Grid item xs={12} sm={6}>
            <Box
              component="img"
              sx={{
                height: 40,
                mb: 4,
              }}
              alt="Town of Bridgewater Logo"
              src="/assets/logo-town.png"
            />
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>
              <Link 
                href="/report-feedback" 
                sx={{ 
                  color: 'black',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                Contact Us
              </Link>
            </Typography>
            <Typography variant="body2" sx={{ mb: 0, color: 'black', fontWeight: 'bold' }}>
              Phone: (902) 530-3621
            </Typography>
            <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
              Email: track@energizebridgewater.ca
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: { xs: 'flex-start', sm: 'flex-end' },
            justifyContent: 'flex-end',  // Push content to bottom
            minHeight: '160px'  // Ensure minimum height matches container
          }}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'flex-start', sm: 'flex-end' },
              gap: 4,
              mb: 0,
            }}>
              <Box>
                <IconButton
                  color="inherit"
                  aria-label="email"
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: 'black',
                    margin: '0 16px 0 0',
                    padding: '4px',
                    width: '32px',
                    height: '32px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  }}
                  href="mailto:info@energizebridgewater.ca"
                >
                  <Email sx={{ fontSize: '20px' }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="facebook"
                  sx={{
                    padding: 0,
                    margin: '0 16px',
                    width: '32px',
                    height: '32px',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                  href="https://www.facebook.com/EnergizeBridgewater"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    component="img"
                    src="/assets/icon-facebook.png"
                    alt="Facebook"
                    sx={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="instagram"
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: 'black',
                    margin: '0 0 0 16px',
                    padding: '4px',
                    width: '32px',
                    height: '32px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  }}
                  href="https://www.instagram.com/bridgewaterenrg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram sx={{ fontSize: '20px' }} />
                </IconButton>
              </Box>
              <Box>
                <Link 
                  href="https://www.energizebridgewater.ca/privacy" 
                  sx={{ 
                    mr: 7,
                    color: 'black',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  Privacy Policy
                </Link>
                <Link 
                  href="https://www.energizebridgewater.ca/privacy" 
                  sx={{ 
                    color: 'black',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  Terms of Use
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
