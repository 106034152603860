// import axios from "axios";
import { handleApiCallsVisualizationEngine } from "./actionHelper";
import {
  DASHBOARD_LAYOUT_REQUEST,
  DASHBOARD_LAYOUT_SUCCESS,
  DASHBOARD_LAYOUT_FAIL,
  DASHBOARD_LAYOUT_KEY_DELETE,
  DASHBOARD_LAYOUT_EDIT_REQUEST,
  DASHBOARD_LAYOUT_EDIT_SUCCESS,
  DASHBOARD_LAYOUT_EDIT_FAIL,
  DASHBOARD_ALL_KPIS_LIST_REQUEST,
  DASHBOARD_ALL_KPIS_LIST_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_FAIL,
  DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_REQUEST,
  DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS,
  DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_FAIL,
  DASHBOARD_KPI_DATA_REQUEST,
  DASHBOARD_KPI_DATA_SUCCESS,
  DASHBOARD_KPI_DATA_DELETE,
  DASHBOARD_KPI_DATA_FAIL,
  DASHBOARD_KPI_DATA_GRAPH_TYPE_CHANGE,
  DASHBOARD_KPI_DATA_UPDATE,
} from "../constants/dashboardConstants";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import { enqueueSnackbar } from "notistack";
import { REACT_KPI } from "../constants/reactionConstants";
import { SET_APPLIED_LOCAL_FILTER } from "../constants/appliedLocalFilterConstant";
import { getKpiData } from "../utils/kpiDataService";
import { getCategoryData } from "../utils/categoryDataService";


export const GetDashboardLayoutData = () => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: DASHBOARD_LAYOUT_REQUEST});

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `user/dashboard`,
      ""
    );

    if (responseData[2] === "") {
      dispatch({
        type: DASHBOARD_LAYOUT_SUCCESS,
        payload: responseData[1].layoutInfo,
      });
    } else {
      dispatch({
      type: DASHBOARD_LAYOUT_FAIL,
      payload: responseData[2],
    });

    dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    
    dispatch({
      type: DASHBOARD_LAYOUT_FAIL,
      payload: error.response?.data.error   || 'Error!',
    });

    dispatch(showGlobalSnackbar(error.response?.data.error  || 'Error!', "error"));
  }
};

export const GetDashboardRefreshLayoutData = () => async (dispatch) => {
  let responseData = [];

  try {

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `user/dashboard`,
      ''
    );

    if (responseData[2] === "") {
      dispatch({
        type: DASHBOARD_LAYOUT_SUCCESS,
        payload: responseData[1].layoutInfo,
      });
    } else {
      dispatch({
        type: DASHBOARD_LAYOUT_FAIL,
        payload: responseData[2],
      });

      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    dispatch({
      type: DASHBOARD_LAYOUT_FAIL,
      payload: error.response.data.error  || 'Error!',
    });
    dispatch(
      showGlobalSnackbar(
        error.response?.data.error  || 'Error!',
        "error"
      )
    );
  }
};

export const GetDashboardAllKpisList = () => async (dispatch) => {
  let responseData = [];

  try {
    dispatch({ type: DASHBOARD_ALL_KPIS_LIST_REQUEST });

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `user/dashboard/kpis`,
      ""
    );

    if (responseData[2] === "") {
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_SUCCESS,
        payload: responseData[1],
      });
    } else {
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    dispatch({
      type: DASHBOARD_ALL_KPIS_LIST_FAIL,
      payload: error.response?.data.error  || 'Error!',
    });
    dispatch(showGlobalSnackbar(error.response?.data.error  || 'Error!', "error"));
  }
};

export const UpdateDashboardAllKpisListItem = (updateDetails) => async (dispatch) => {
  let responseData = [];
  try {
    if (updateDetails[0] === false){
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS,
        payload: updateDetails[1],
      });
    }
    else{
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS,
        payload: updateDetails[1],
      });
    }
    
    dispatch({ type: DASHBOARD_ALL_KPIS_LIST_ITEM_EDIT_REQUEST });

    responseData = await handleApiCallsVisualizationEngine(
      "post",
      `user/dashboard/kpi/state`, 'manageViewDashboard',
      { isActive: updateDetails[0], kpiBasicInformationId: updateDetails[1] }
    ); 

    if (responseData[2] === ''){
      if (updateDetails[0] === false) {
        dispatch({
          type: DASHBOARD_LAYOUT_KEY_DELETE,
          payload: updateDetails[1].toString(),
        });
        dispatch({
          type: DASHBOARD_KPI_DATA_DELETE,
          payload: updateDetails[1],
        });
        dispatch(showGlobalSnackbar("Card Removed from Dashboard.", "success"));
      } else if (updateDetails[0] === true) {
        dispatch(GetDashboardRefreshLayoutData());
        dispatch(showGlobalSnackbar("New Card Added to Dashboard.", "success"));
      }
    }else{
      var errorData = responseData[2];
      if (updateDetails[0] === false) {
        dispatch({
          type: DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS,
          payload: updateDetails[1],
        });
        enqueueSnackbar(`Unable to remove Card.`, { variant: "error" });
        enqueueSnackbar(errorData, { variant: "error" });
      } else {
        dispatch({
          type: DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS,
          payload: updateDetails[1],
        });
        enqueueSnackbar(`Unable to Add new Card.`, { variant: "error" });
        enqueueSnackbar(errorData, { variant: "error" });
      }
    }
  } catch (error) {
    var errorData = error.response.data.error  || "Error!";
    if (updateDetails[0] === false) {
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_EDIT_ADD_SUCCESS,
        payload: updateDetails[1],
      });
      enqueueSnackbar(`Unable to remove Card.` , { variant: "error" });
      enqueueSnackbar(errorData, { variant: "error" });
    } else {
      dispatch({
        type: DASHBOARD_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS,
        payload: updateDetails[1],
      });
      enqueueSnackbar(`Unable to Add new Card.`, { variant: "error" });
      enqueueSnackbar(errorData, { variant: "error" });
    }
  }
};

export const GetDashboardEachKpiData = (kpiId) => async (dispatch,getState) => {
  let responseData = [];
  try {
    dispatch({ type: DASHBOARD_KPI_DATA_REQUEST });
    
    responseData = await getKpiData(kpiId);
      
      if (responseData[2] === "") {
      await dispatch({
        type: DASHBOARD_KPI_DATA_SUCCESS,
        payload: responseData[1],
      });
      
      const reactionReducer = getState().reactionReducer
      const {allKpiReactions} = reactionReducer
      // console.log("allKpiReactions",allKpiReactions)
      let newAllKpiReactions = { ...allKpiReactions }
      newAllKpiReactions[kpiId] = responseData[1]["kpiReaction"]

      await dispatch({
        type: REACT_KPI,
        payload: newAllKpiReactions
      })
    } else {
      dispatch({
        type: DASHBOARD_KPI_DATA_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    dispatch({
      type: DASHBOARD_KPI_DATA_FAIL,
      payload: error.response?.data.error  || 'Error!',
    });
    dispatch(showGlobalSnackbar(error.response?.data.error  || 'Error!', "error"));
  }
};

export const UpdateDashboardLayoutData = (layoutData) => async (dispatch) => {
  let responseData = [];

    try {
      dispatch({ type: DASHBOARD_LAYOUT_EDIT_REQUEST });

      responseData = await handleApiCallsVisualizationEngine(
        "post",
        `user/dashboard/layout/update`,
        '',
        { layoutInfo: layoutData }
      );

      if (responseData[2] === "") {
        dispatch({
          type: DASHBOARD_LAYOUT_EDIT_SUCCESS,
          payload: layoutData,
        });
      } else {
        dispatch({
          type: DASHBOARD_LAYOUT_EDIT_FAIL,
          payload: responseData[2],
        });
        dispatch(showGlobalSnackbar(responseData[2], "error"));
      }

    } catch (error) {
      dispatch({
        type: DASHBOARD_LAYOUT_EDIT_FAIL,
        payload: error.response.data.error  || 'Error!',
      });
      dispatch(
        showGlobalSnackbar(error.response.data.error  || 'Error!', "error")
      );
    }
  };

export const UpdateDashboardOneKpiGraphType = (changedGraphTypeDetails) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_KPI_DATA_GRAPH_TYPE_CHANGE,
    payload: changedGraphTypeDetails,
  });
};

export const ResetDashboardLayoutData = () => async (dispatch) => {
  let responseData = [];
  try {

    let userdata = { user: 1 };

    responseData = await handleApiCallsVisualizationEngine(
      "post",
      `visualizationEngine/dashboard/set-default-kpi`, 'setDefaultKpi',
      userdata
    );

    if (responseData[2] === "") {
      dispatch(GetDashboardRefreshLayoutData());
      dispatch(GetDashboardAllKpisList());
    } else {
      dispatch({
        type: DASHBOARD_LAYOUT_FAIL,
        payload: responseData[2],
      });
      dispatch(showGlobalSnackbar(responseData[2], "error"));
    }
 
  } catch (error) {
    dispatch({
      type: DASHBOARD_LAYOUT_FAIL,
      payload: error.response.data.error  || 'Error!',
    });
    dispatch(
      showGlobalSnackbar(
        error.response.data.error  || 'Error!',
        "error"
      )
    );
  }
};

export const UpdateDashboardOneKpiGraphDataByFilter = (filterData, kpiId) => async (dispatch,getState) => {
  
  let responseData = [];
  
  try {
    dispatch({ type: DASHBOARD_KPI_DATA_REQUEST });

    responseData = await handleApiCallsVisualizationEngine(
      "get",
      `kpi/${kpiId}/data?filters=${JSON.stringify(filterData)}`,
      ""
    );
    
    if (responseData[2] === "") {
      await dispatch({
        type: DASHBOARD_KPI_DATA_SUCCESS,
        payload: responseData[1],
      });

      let newLocalFilter = {...getState().appliedLocalFilter.localFilter}
      newLocalFilter[kpiId]= filterData
      await dispatch({
        type:SET_APPLIED_LOCAL_FILTER,
        payload:newLocalFilter
      })
      
      console.log("getState().appliedLocalFilter",getState().appliedLocalFilter)

    } else {
      await dispatch({
        type: DASHBOARD_KPI_DATA_FAIL,
        payload: responseData[2],
      });
      await dispatch(showGlobalSnackbar(responseData[2], "error"));
    }

  } catch (error) {
    await dispatch({
      type: DASHBOARD_KPI_DATA_FAIL,
      payload: error.response.data.error  || 'Error!',
    });

    await dispatch(showGlobalSnackbar(error.response.data.error  || 'Error!', "error"));
  }
};

export const GetDashboardCategoryData = (categoryId) => async (dispatch) => {
  try {
    const categoryData = await getCategoryData(categoryId);
    // You can dispatch an action here if needed, for example:
    // dispatch({ type: DASHBOARD_CATEGORY_DATA_SUCCESS, payload: categoryData });
    return categoryData;
  } catch (error) {
    // Handle the error, maybe dispatch an error action
    dispatch(showGlobalSnackbar(error.message, "error"));
    throw error;
  }
};
