import { handleApiCallsVisualizationEngine } from "../actions/actionHelper";

const kpiDataCache = new Map();
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getCachedKpiData = (kpiId) => {
  const cachedData = kpiDataCache.get(kpiId);
  if (cachedData && Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
    return cachedData.data;
  }
  return null;
};

const setCachedKpiData = (kpiId, data) => {
  kpiDataCache.set(kpiId, { data, timestamp: Date.now() });
};

const isDebugMode = process.env.NODE_ENV === 'debug';

const debugLog = (message) => {
  if (isDebugMode) {
    console.log(`[KPI Debug]: ${message}`);
  }
};

export const getKpiData = async (kpiId) => {
  const cachedData = getCachedKpiData(kpiId);
  if (cachedData) {
    debugLog(`Using cached data for KPI ${kpiId}`);
    return cachedData;
  }

  debugLog(`Fetching fresh data for KPI ${kpiId}`);
  const kpiRes = await handleApiCallsVisualizationEngine(
    "get",
    `kpi/${kpiId}/data`,
    ""
  );

  setCachedKpiData(kpiId, kpiRes);
  return kpiRes;
};

export const clearKpiDataCache = () => {
  kpiDataCache.clear();
};
