import React, { useState, useEffect } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { tokens } from "../../theme";
import TextTransition, { presets } from 'react-text-transition';
import { getEnabledDailyTips } from '../../actions/manageDailyTipsActions';
import { useDispatch } from 'react-redux';

function DailyTips() {
  const {enabledDailyTips} = useSelector((state) => state.manageDailyTipsReducer)
  const dispatch = useDispatch()

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isVerySmallMobile = useMediaQuery('(max-width:500px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;

  const [dailyTipsData, setDailyTipsData] = useState(["No daily tips configured","No daily tips configured ","No daily tips configured  "])
  const [textIndex, settextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => settextIndex((textIndex) => textIndex + 1),
      86400000, // every 1 day
    );
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    dispatch(getEnabledDailyTips())
  }, [])

  useEffect(() => {
    if(enabledDailyTips && enabledDailyTips.length > 0) {
      let enabledDailyTipsContent = enabledDailyTips.map(tip => tip.tip_content);
      setDailyTipsData([...enabledDailyTipsContent])
    }
  }, [enabledDailyTips])

  const getBoxStyles = () => {
    const chatBotWidth = isVerySmallMobile ? 60 : isMobile ? 70 : 80; // pixels
    const containerPadding = 40; // 20px on each side
    
    const availableWidthPercent = "calc(90% - " + (chatBotWidth + containerPadding) + "px)";

    if (isVerySmallMobile) {
      return {
        width: availableWidthPercent,
        minHeight: "12px",
        fontSize: '0.6rem',
        position: 'relative',
        right: chatBotWidth / 2
      }
    } else if (isMobile) {
      return {
        width: availableWidthPercent,
        minHeight: "14px",
        fontSize: '0.7rem',
        position: 'relative',
        right: chatBotWidth / 2
      }
    } else if (isTablet) {
      return {
        width: availableWidthPercent,
        minHeight: "16px",
        fontSize: '0.8rem',
        position: 'relative',
        right: chatBotWidth / 2
      }
    } else {
      return {
        width: availableWidthPercent,
        minHeight: "18px",
        fontSize: '1rem',
        position: 'relative',
        right: chatBotWidth / 2
      }
    }
  }

  const boxStyles = getBoxStyles();

  return (
    isAuthenticated ? (
      <Box
        {...boxStyles}
        bottom={0.5}
        bgcolor={colors.dailyTipsBackground}
        sx={{ 
          borderRadius: '16px',
          opacity: 0.9,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            opacity: 1
          },
          py: 0.5,
          ml: 0
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        mb={1}
      >
        <Box 
          pl={isVerySmallMobile ? 3 : isMobile || isTablet ? 4 : 5}
          alignItems="center"
          justifyContent="flex-start"
          display="flex"
          flexDirection="row"
        >
          <Typography 
            variant={isVerySmallMobile ? "caption" : isMobile || isTablet ? "body2" : "body1"}
            sx={{ 
              fontWeight: 700,
              color: colors.primary[100],
              marginRight: '8px'
            }}
          >
            Daily Tips:
          </Typography>
          <Box pl={1.5} sx={{ 
            maxWidth: isVerySmallMobile ? '100px' : isMobile ? '150px' : isTablet ? '250px' : '500px', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
            color: colors.primary[200]
          }}>
            <TextTransition inline={true} springConfig={presets.gentle}>
              <span dangerouslySetInnerHTML={{ __html: dailyTipsData[textIndex % dailyTipsData.length] }} />
            </TextTransition>
          </Box>
        </Box>
      </Box>
    ) : (
      <></>
    )
  )
}

export default DailyTips
