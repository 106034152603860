import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Tooltip, IconButton, Typography, InputAdornment } from '@mui/material';
import ManualArticleCards from "./manualArticleCards"
import Carousel from "react-multi-carousel";
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme'; 
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import DocumentationCategoryModify from '../../../components/Documentation/DocumentationCategoryModifyPopUp';
import { useDocumentationData } from '../../../hooks/useDocumentationData';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 2,
    partialVisibilityGutter: 20
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40
  }
};

export default function AdminDocumentation() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { categoryData, articleData, loading, error } = useDocumentationData();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setFilteredData(null);
      return;
    }

    const filtered = [];
    Object.entries(articleData).forEach(([categoryId, articles]) => {
      const matchedArticles = articles.filter(article =>
        article.documentation_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stripHtml(article.documentation_details).toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (matchedArticles.length > 0) {
        filtered.push({
          category_id: categoryId,
          category_name: categoryData.find(cat => cat.category_id === categoryId)?.category_name,
          articles: matchedArticles
        });
      }
    });
    setFilteredData(filtered.length > 0 ? filtered : null);
  };

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredData(null);
    }
  }, [searchTerm]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ padding: "0em 2em 2em 2em", overflowX: "auto" }}>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", paddingTop: "10px" }}>
        <h1 paddingBottom={"5px"}>Learn more</h1>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px", flexWrap: "wrap" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search documentation"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '300px' }}
          />
          <Tooltip title="Add Documentation" placement="left">
            <Button
              variant="contained"
              aria-haspopup="true"
              startIcon={<PlaylistAddIcon style={{color:`${colors.primary[100]}`}}/>}
              style={{backgroundColor:`${colors.primary[700]}`}}
              disabled={categoryData?.length > 0 ? false : true}
              component={Link}
              to="/manage-documentation/documentation-authoring"
            > 
              <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.primary[100]}`}>
                Add Documentation 
              </Typography>
            </Button>
          </Tooltip>
          <DocumentationCategoryModify 
            categoryDetails={{
              category_details: null,
              category_name: null
            }} 
            action={"add"}
          />
        </Box>
      </Box>

      {(filteredData || categoryData) == null ? (
        <Skeleton variant="rounded" animation="wave" height={'70vh'} width={'95vw'} />
      ) : (
        (filteredData || categoryData).map((categoryElm, i) => {
          const articlesToRender = filteredData ? categoryElm.articles : articleData[categoryElm.category_id];

          if (!articlesToRender || articlesToRender.length === 0) return null;

          return (
            <Box key={i} sx={{paddingTop: "25px"}}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "10px" }}>
                <Typography variant='h4' paddingBottom={1}>
                  {categoryElm.category_name}
                </Typography>
                {!filteredData && (
                  <DocumentationCategoryModify categoryDetails={categoryElm} action={"edit"}/>
                )}
              </Box>
              <Box>
                <Carousel
                  draggable={true}
                  responsive={responsive}
                  infinite={true}
                  keyBoardControl={true}
                  partialVisible={true}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-100-px"
                >
                  {Array.isArray(articlesToRender) ? (
                    articlesToRender.map((articleElm, i) => (
                      <Box key={i} sx={{ marginRight: "3px" }}>
                        <ManualArticleCards
                          key={articleElm.roleId}
                          CardData={articleElm}
                          actualCardData={categoryElm}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography variant='h6'>
                        Add Documents for {categoryElm.category_name}
                      </Typography>
                    </Box>
                  )}
                </Carousel>
              </Box>
            </Box>
          );
        })
      )}
    </div>
  );
}