import { handleApiCallsVisualizationEngine } from "../actions/actionHelper";
import { getKpiData } from "./kpiDataService";

export const getCategoryData = async (categoryId) => {
  try {
    const responseData = await handleApiCallsVisualizationEngine(
      "get",
      `categories/${categoryId}`,
      ""
    );

    if (responseData[2] !== "") {
      throw new Error(responseData[2]);
    }

    const categoryData = responseData[1];

    if (categoryData.kpiIds && categoryData.kpiIds.length > 0) {
      // console.log(`Getting KPI data for category ${categoryId} with kpiIds ${categoryData.kpiIds}`);
      const kpiDataPromises = categoryData.kpiIds.map(kpiId => getKpiData(kpiId));
      const kpiDataResults = await Promise.all(kpiDataPromises);
      categoryData.kpiData = kpiDataResults.map(result => result[1]);
      // console.log(`KPI data for category ${categoryId} with kpiIds ${categoryData.kpiIds} has been fetched`);
    }

    return categoryData;
  } catch (error) {
    console.error(`Error fetching category data for categoryId ${categoryId}:`, error);
    throw error;
  }
};
