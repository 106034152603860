import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Topbar from "./screens/global/Topbar";
import ChatBotButton from "./screens/global/chatBot";
import DailyTips from "./screens/global/dailyTips";
import Dashboard from "./screens/User/dashboard";
import DeviceManagement from "./screens/User/deviceManagement";
import UserAnalytics from "./screens/User/analytics";
import Settings from "./screens/settings";
import Notifications from "./screens/User/notification";
import UserAccount from "./screens/User/userAccountManagement";
import Documentation from "./screens/User/documentation";
import FAQPage from "./screens/User/faq";
import MissingPage from "./screens/MissingPage";
import UserFeedback from "./screens/User/userFeedback";
import UserManageAdmin from "./screens/Admin/adminUsersManage";
import RoleManageAdmin from "./screens/Admin/rolesManage";
import DocumentaionModify from "./screens/Admin/ContentManage/documentaion";
import AdminFAQ from "./screens/Admin/ContentManage/adminFAQ/adminFAQIndex";
import AuditLog from "./screens/Admin/auditLog";
import LogOut from "./screens/logout";
import LogInPage from "./screens/LogIn";
import ForgetPasswordPage from "./screens/forgetPassword";
import DocumentaionDetails from "./screens/User/documentaionDetailsPage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoutes from "./PrivateRoute";
import CustomGlobalSnackbar from "./components/SnackBarAlert/CustomGlobalSnackBar";
import CustomStackedSnackbar from "./components/SnackBarAlert/CustomStackedSnackbar";
import AuthContext, {AuthProvider} from "./Auth/authProvider";
import AdminDocumentation from "./screens/User/adminDocumentation";
import AdminManagement from "./screens/Admin/adminManagement/adminManagementIndex";
import ManageDailyTips from "./screens/Admin/ContentManage/manageDailyTips/manageDailyTips";
import { OTPPage } from "./screens/OTPPage/OTPPage";
import TempPassword from "./screens/TempPassword/TempPassword";
import QRCodeComponent from "./screens/QRCode/QRCode";
import AccountLockedComponent from "./screens/AccountLocked/AccountLocked";
import { ResetPasswordOTPPage } from "./screens/forgetPassword/resetPasswordOTP";

import KpiManagement from "./screens/Admin/AdminDashboard/kpiManagement";
import KpiEdit from "./screens/Admin/AdminDashboard/kpiManagement/kpiEdit";
import FilterManagement from "./screens/Admin/AdminDashboard/filterManagement";
import FilterEdit from "./screens/Admin/AdminDashboard/filterManagement/filterEdit";
import DatabaseManagement from "./screens/Admin/AdminDashboard/databaseManagement";
import DatabaseEdit from "./screens/Admin/AdminDashboard/databaseManagement/databaseEdit";
import DashboardManagement from "./screens/Admin/AdminDashboard/dashboardManagement";
import CategoryManagement from "./screens/Admin/AdminDashboard/categoryManagement";
import DashboardPreview from "./screens/Admin/AdminDashboard/dashboardManagement/dashboardPreview";
import { UserAndDevices } from "./screens/User/userAndDevices/userAndDevices";
import { useLocation } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { USER_LOGOUT_SUCCESS } from "./constants/userConstants";
import Footer from "./screens/global/Footer";

function App() {
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const useAuthContext = useContext(AuthContext)
  // const userLogin = useSelector((state)=>state.userLogin)
  // const {isAuthenticated, userInfo} = userLogin
  useEffect(()=>{
    if((pathname === "/login" || pathname === "/OTP-Authentication") && sessionStorage.getItem("authTokens")){
      // Logout 
       sessionStorage.clear()
       dispatch({
        type:USER_LOGOUT_SUCCESS
      })
       navigate("/login")
    }
  },[pathname])

  const [theme, colorMode] = useMode();

  const blockContextMenu = (event) => {
    event.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 123) {
      // F12 key is pressed
      event.preventDefault();
    } else if (
      (event.ctrlKey && event.shiftKey && event.keyCode == 73) ||
      (event.ctrlKey && event.shiftKey && event.keyCode == 74) ||
      (event.ctrlKey && event.shiftKey && event.keyCode == 67)
    ) {
      // ctrl+shift+I or ctrl+shift+J or ctrl+shift+C key is pressed
      event.preventDefault();
    }
  };

  // Do not Remove

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyPress);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Topbar />
            <div className="content" style={{ padding: '0 60px' }}>
              <CustomGlobalSnackbar />
              <CustomStackedSnackbar />
              <Routes>
                <Route path="/login" element={<LogInPage />} />
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/admin-manage" element={<AdminManagement />} />
                  <Route path="/device-manage" element={<DeviceManagement />} />
                  <Route path="/analytics" element={<UserAnalytics />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/user-account" element={<UserAccount />} />
                  <Route path="/logout" element={<LogOut />} />
                  <Route path="/documentation" element={<Documentation />} />
                  <Route path="/faq" element={<FAQPage />} />
                  <Route path="/report-feedback" element={<UserFeedback />} />
                  <Route path="/users-manage" element={<UserManageAdmin />} />
                  <Route
                    path="/role-permission"
                    element={<RoleManageAdmin />}
                  />
                  <Route path="/audit-log" element={<AuditLog />} />
                  <Route
                    path="/documentation/:id"
                    element={<DocumentaionDetails />}
                  />
                  <Route
                    path="/manage-documentation/documentation-authoring"
                    element={<DocumentaionModify />}
                  />
                  <Route path="/faq-create" element={<AdminFAQ />} />
                  <Route path="/manage-documentation" element={<AdminDocumentation />} />
                  <Route path="/manage-daily-tips" element={<ManageDailyTips />} />
                  <Route path="/filter-manage" element={<FilterManagement />} />
                  <Route path="/filter-manage/edit" element={<FilterEdit />} />
                  <Route path="/kpi-manage" element={<KpiManagement />} />
                  <Route path="/kpi-manage/edit" element={<KpiEdit />} />
                  <Route path="/db-manage" element={<DatabaseManagement />} />
                  <Route path="/db-manage/edit" element={<DatabaseEdit />} />
                  <Route path="/manage-dashboard" element={<DashboardManagement />} />
                  <Route path="/manage-category" element={<CategoryManagement />} />
                  <Route path="/manage-dashboard/preview" element={<DashboardPreview />} />
                  <Route path="/users-and-devices" element={<UserAndDevices />} />
                </Route>
                <Route path="/forget-password" element={<ForgetPasswordPage />} />
                <Route path="*" element={<MissingPage />} />
                <Route path="/OTP-Authentication" element={<OTPPage />} />
                <Route path="/Temporary-Password-page" element={<TempPassword />} />
                <Route path="/QRCode-Authentication" element={<QRCodeComponent />} />
                <Route path="/Account-Locked" element={<AccountLockedComponent />} />
                <Route path="/Reset-Password" element={<ResetPasswordOTPPage />} />
              </Routes>
            </div>
            <div className="bottom-content" style={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center', 
              position: 'relative', 
              marginBottom: '20px',
              marginTop: '20px',
              padding: '0 20px',
            }}>
              <DailyTips />
              <div style={{ position: 'absolute', right: '50px' }}>
                <ChatBotButton />
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
