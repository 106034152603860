import { useState, useEffect } from 'react';
import { fetchUserDashboardData } from '../utils/dashboardDataService';
import { handleApiCallsVisualizationEngine } from '../actions/actionHelper';

const useFetchUserDashboardLayout = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [layoutInfo, setLayoutInfo] = useState(null);
  const [kpiData, setKpiData] = useState({});
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const { layoutInfo: layout, kpiData: kpis, categoryData: categories } = await fetchUserDashboardData();

        setLayoutInfo(layout);
        setKpiData(kpis);
        setCategoryData(categories);

      } catch (err) {
        console.error("Error fetching dashboard data", err);
        setError(err.message || 'Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateLayout = async (newLayout) => {
    try {
      await handleApiCallsVisualizationEngine(
        'post',
        'user/dashboard/layout/update',
        '',
        { layoutInfo: newLayout }
      );
      setLayoutInfo(newLayout);
    } catch (err) {
      setError(err.message || 'Failed to update layout');
    }
  };

  return {
    loading,
    error,
    layoutInfo,
    kpiData,
    categoryData,
    updateLayout,
  };
};

export default useFetchUserDashboardLayout;
