import { useState, useEffect } from 'react';
import { handleApiCalls } from '../actions/actionHelper';

export const useDocumentationData = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [articleData, setArticleData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [categoryResp, articleResp] = await Promise.all([
          handleApiCalls("get", "documentation/category-list", "viewDocumentationCategory"),
          handleApiCalls("get", `documentation/documentation-get`, `viewDocumentation`)
        ]);

        if (categoryResp[2] !== "" || articleResp[2] !== "") {
          throw new Error(categoryResp[2] || articleResp[2]);
        }

        setCategoryData(categoryResp[1]);
        setArticleData(articleResp[1]);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { loading, error, categoryData, articleData };
};
