import { useState, useEffect } from 'react';
import { fetchDashboardData } from '../utils/dashboardDataService';

export const useFetchDashboardLayoutData = (dashboardName) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [layoutInfo, setLayoutInfo] = useState(null);
  const [kpiData, setKpiData] = useState({});
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    fetchDashboardLayoutData(dashboardName);
  }, [dashboardName]);

  const fetchDashboardLayoutData = async (name) => {
    setLoading(true);
    setError(null);

    try {
      const { layoutInfo: layout, kpiData: kpis, categoryData: categories } = await fetchDashboardData(name);

      setLayoutInfo(layout);
      setKpiData(kpis);
      setCategoryData(categories);

    } catch (error) {
      setError(error.message || 'An error occurred while fetching dashboard layout data');
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => {
    fetchDashboardLayoutData(dashboardName);
  };

  return { loading, error, layoutInfo, kpiData, categoryData, refetch };
};
