import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DashboardCard from "./DashboardCard";
import { GetDashboardEachKpiData } from '../../actions/dashboardActions';
import { GetAdminDashboardPreviewEachKpiData } from '../../actions/dashboardPreviewActions';

// TODO: refactor this to use the new useFetchDashboardKpisData hook and remove DashboardCardContainer altogther and have parent component handle the data fetching and use DashboardCard for rendering
const DashboardCardContainer = React.forwardRef(
  function DashboardCardContainer({ children, item, isAdmin, kpiData, ...props }, ref) {
    const dispatch = useDispatch();

    const myDashboardKpisData = useSelector(state => state.myDashboardKpisData);
    const { error: dashboardKpisDataError, dashboardKpisData } = myDashboardKpisData;

    useEffect(() => {
      if (!isAdmin) {
        dispatch(GetDashboardEachKpiData(item));
      } else if (!kpiData) {
        dispatch(GetAdminDashboardPreviewEachKpiData(item));
      }
    }, [dispatch, item, isAdmin, kpiData]);

    const data = isAdmin ? kpiData : dashboardKpisData[item];
    const error = isAdmin ? (kpiData ? '' : 'Error loading KPI data') : dashboardKpisDataError[item];

    return (
      <DashboardCard 
        data={data} 
        root={item} 
        kpisdataerror={error} 
        {...props} 
        ref={ref}
      >
        {children}
      </DashboardCard>
    );
  }
);

export default DashboardCardContainer;
