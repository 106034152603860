import React, { useEffect, useState, useCallback } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import DashboardCardContainer from './DashboardCardContainer';
import CategoryCard from '../Cards/CategoryCards/CategoryCard';
import { Box } from '@mui/material';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DragAndDropLayout({ 
  userDefineLayout, 
  onLayoutChange, 
  isAdmin = false, 
  dashboard_id,
  categoryData,
  kpisData,
  updateLayoutOnChange = true
}) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [previousLayout, setPreviousLayout] = useState(userDefineLayout);
  const [mounted, setMounted] = useState(false);

  // Handle initial mount
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (categoryData && kpisData) {
      setIsDataLoaded(true);
    }
  }, [categoryData, kpisData]);

  useEffect(() => {
    setPreviousLayout(userDefineLayout);
  }, [userDefineLayout]);

  const handleLayoutChange = useCallback((layout, layouts, section) => {
    if (updateLayoutOnChange && mounted) {
      const hasLayoutChanged = JSON.stringify(layouts) !== JSON.stringify(previousLayout);
      
      if (hasLayoutChanged) {
        console.log(`Layout update called`);
        onLayoutChange(layout, layouts, dashboard_id, section);
        setPreviousLayout(layouts);
      }
    }
  }, [updateLayoutOnChange, onLayoutChange, dashboard_id, previousLayout, mounted]);

  if (!isDataLoaded) return <Box>Loading...</Box>;

  return (
    <Box className="dashboard-container">
      {/* Categories Section */}
      {categoryData && Object.keys(categoryData).length > 0 && (
        <Box className="categories-section" sx={{ mb: '50px' }}>
          {Object.entries(categoryData).map(([categoryId, data]) => (
            <Box 
              key={`category-${categoryId}`} 
              sx={{ width: '100%', mb: '50px' }}
            >
              <CategoryCard 
                key={`category-${categoryId}`}
                categoryData={data}
              />
            </Box>
          ))}
        </Box>
      )}

      {/* KPIs Section */}
      {kpisData && Object.keys(kpisData).length > 0 && (
        <Box className="kpis-section">
          <ResponsiveGridLayout
            className="dashboard-layout"
            layouts={userDefineLayout}
            onLayoutChange={(layout, layouts) => handleLayoutChange(layout, layouts, 'dashboard')}
            isDraggable
            isRearrangeable
            draggableHandle=".grid-item__title"
            draggableCancel='.grid-item-non-draggable__title'
            breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
          >
            {userDefineLayout.lg.map((item) => (
              <DashboardCardContainer 
                key={item.i} 
                item={Number(item.i)}
                kpiData={kpisData[Number(item.i)]}
                isAdmin={isAdmin}
              />
            ))}
          </ResponsiveGridLayout>
        </Box>
      )}
    </Box>
  );
}
